<template>
  <section>
    <div class="text-center mb-1">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="gradient-primary"
        class="btn-icon"
        size="md"
        :href="`https://www.indetec.gob.mx/delivery?srv=0&sl=3&path=${filepdf.rutaDoc}${filepdf.extBase}`"
        target="_blank"
      >
        <feather-icon icon="FileIcon" />
        Ver PDF
      </b-button>
    </div>

  </section>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    filepdf: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style>

</style>
