<template>
  <section>
    <div class="mb-2">
      <btnpdf
        :filepdf="edicion"
        class="float-right"
      />
      <br>
    </div>
    <b-skeleton-wrapper :loading="loader">
      <template #loading>
        <b-skeleton
          type="button"
          width="100%"
        />
        <div class="action-bar-id mb-2">
          <b-button-toolbar
            key-nav
            aria-label="Toolbar with button groups"
            class="demo-inline-spacing"
            vertical
          >
            <!-- group  1 -->
            <b-button-group>
              <b-skeleton type="button" />
            </b-button-group>

            <span>
              <b-skeleton
                type="button"
                width="8rem"
              />
            </span>

            <!-- group 3 -->
            <b-button-group>
              <b-skeleton type="button" />
            </b-button-group>
          </b-button-toolbar>
        </div>

        <div class="mb-2 text-center">
          <b-skeleton-img />
        </div>
      </template>

      <flipbook
        v-slot="flipbook"
        ref="flipbook"
        class="flipbook"
        :pages="pages"
        :start-page="pageNum"
        :click-to-zoom="false"
        :zooms="[1, 2]"
        @flip-left-end="sliderPage = $event"
        @flip-right-end="sliderPage = $event"
      >
        <div>
          <vue-slider
            v-model="sliderPage"
            direction="ltr"
            lazy
            contained
            :interval="2"
            :min="sliderConfig.min"
            :max="sliderConfig.max"
          />
        </div>

        <div class="action-bar-id mb-2">
          <b-button-toolbar
            key-nav
            aria-label="Toolbar with button groups"
            class="demo-inline-spacing"
            vertical
          >
            <!-- group  1 -->
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon"
                variant="primary"
                :disabled="!flipbook.canFlipLeft"
                @click="flipbook.flipLeft"
              >
                <feather-icon icon="ChevronLeftIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon"
                variant="primary"
                :disabled="!flipbook.canZoomOut"
                @click="flipbook.zoomOut"
              >
                <feather-icon icon="ZoomOutIcon" />
              </b-button>
            </b-button-group>

            <span class="page-num">
              Página <b>{{ flipbook.page }}</b> de <b>{{ flipbook.numPages }}</b>
            </span>

            <!-- group 3 -->
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon"
                variant="primary"
                :disabled="!flipbook.canZoomIn"
                @click="flipbook.zoomIn"
              >
                <feather-icon icon="ZoomInIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon"
                variant="primary"
                :disabled="!flipbook.canFlipRight"
                @click="flipbook.flipRight"
              >
                <feather-icon icon="ChevronRightIcon" />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
      </flipbook>
    </b-skeleton-wrapper>
  </section>
</template>

<script>
/* eslint-disable import/no-cycle */
import store from '@/store'
import Flipbook from 'flipbook-vue'
import {
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BSkeleton,
  BSkeletonImg,
  BSkeletonWrapper,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onMounted, watch } from '@vue/composition-api'
import { useRouter, isObject, isEmptyObject } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSlider from 'vue-slider-component'
import Btnpdf from '@/views/components/btnPdf.vue'

export default {
  components: {
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BSkeleton,
    BSkeletonImg,
    BSkeletonWrapper,
    Flipbook,
    VueSlider,
    Btnpdf,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { route, router } = useRouter()
    const toast = useToast()
    const loader = ref(false)
    const edicion = ref({})
    const pageNum = ref(null)
    const sliderPage = ref(0)
    const pages = ref([null])
    const sliderConfig = ref({ min: 0, max: 2 })

    const loadAllImages = () => {
      store.dispatch('ediciones/fetchImagesToPreview', edicion.value.rutaDoc).then(response => {
        const { status, message, files } = response
        if (status === 200 && files.length) {
          sliderConfig.value.max = (files.length % 2 === 0) ? files.length : files.length - 1
          sliderPage.value = 1

          for (let i = 0; i < files.length; i += 1) pages.value.push(`https://www.indetec.gob.mx/delivery?srv=0&sl=0&path=${edicion.value.rutaDoc}/${files[i]}`)
          loader.value = false
        }

        if (status !== 200) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Información de solicitud',
              text: message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          },
          { timeout: 8000 })
        }
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Vuelva a intentarlo más tarde',
            text: err,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        },
        { timeout: 8000 })
      })
    }

    const loadEdiciones = () => {
      loader.value = true

      store.dispatch('ediciones/fetchItems').then(response => {
        if (response.status !== 200) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Información de solicitud',
              text: response.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          },
          { timeout: 8000 })
        }

        edicion.value = store.getters['ediciones/detalleEdicion'](route.value.params.x)
        if (!isObject(edicion.value) || isEmptyObject(edicion.value)) router.push('/error-404')
        else loadAllImages()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Vuelva a intentarlo más tarde',
            text: err,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        },
        { timeout: 8000 })
      })
    }

    onMounted(() => loadEdiciones())

    watch(sliderPage, val => {
      if (pageNum.value !== val) pageNum.value = val
    })

    return {
      pages,
      edicion,
      loader,
      sliderPage,
      pageNum,
      sliderConfig,
    }
  },
}
</script>

<style>
.flipbook {
  height: 110vh;
}

.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 50px - 40px) !important;
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

.action-bar-id {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
